import React, { Fragment, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { BtnPrimary, BtnSecondary, Input, Select } from "../../components";
import { TemplateContainer, Form, Centered } from "../style";

import { create, update } from "../../../domain/services/user.service";

const Template = ({ action, user, close }) => {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    role: "",
  });

  useEffect(() => {
    if (action === 2) {
      populateForm();
    }
  }, [action]);

  const populateForm = () => {
    setForm(user);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    action === 1 ? createUser(form) : updateUser(form);
  };

  const createUser = (_form) => {
    try {
      create(_form);
      toast.success("User created successfully");
      close();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateUser = async (_form) => {
    try {
      let updateFields = {};
      var aKeys = Object.keys(user).sort();
      var bKeys = Object.keys(_form).sort();

      for (var i = 0; i < aKeys.length; i++) {
        if (user[aKeys[i]] !== _form[bKeys[i]]) {
          updateFields[bKeys[i]] = _form[bKeys[i]];
        }
      }

      updateFields["id"] = user.id;
      const res = await update(updateFields);
      if (res.status) {
        toast.error(res.message);
        return;
      }
      toast.success("User updated successfully");
      close();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  return (
    <Fragment>
      <h1 className="text-center">Users</h1>
      <TemplateContainer>
        <Centered>
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              name="firstName"
              placeholder="firstName"
              required
              value={form.firstName}
              onChange={handleChange}
            />
            <Input
              type="text"
              name="lastName"
              placeholder="lastName"
              required
              value={form.lastName}
              onChange={handleChange}
            />
            <Input
              type="text"
              name="userName"
              required
              placeholder="userName"
              value={form.userName}
              onChange={handleChange}
            />
            <Input
              type="email"
              name="email"
              placeholder="email"
              required
              value={form.email}
              onChange={handleChange}
            />
            <Select
              name="role"
              value={form.role}
              required
              onChange={handleChange}
            >
              <option value="" hidden>
                {form.role ? form.role : "role"}
              </option>
              <option value="admin">Admin</option>
              <option value="editor">Editor</option>
            </Select>
            <Centered>
              <BtnSecondary onClick={() => close()}>Cancel</BtnSecondary>
              <BtnPrimary type="submit">Save</BtnPrimary>
            </Centered>
          </Form>
        </Centered>
      </TemplateContainer>
    </Fragment>
  );
};

export default Template;
