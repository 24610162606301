import {
  DashboardPage,
  CategorysPage,
  ArticlesPage,
  BrandsPage,
  ProjectsPage,
  UsersPage
} from "../pages";

const routes = [
  {
    path: "/",
    name: "Tablero",
    icon: "apps-outline",
    component: DashboardPage,
  },
  {
    path: "/categories",
    name: "Categorías",
    icon: "bookmarks-outline",
    component: CategorysPage,
  },
  {
    path: "/brands",
    name: "Marcas",
    icon: "ribbon-outline",
    component: BrandsPage,
  },
  {
    path: "/articles",
    name: "Artículos",
    icon: "cart-outline",
    component: ArticlesPage,
  },
  {
    path: "/projects",
    name: "Proyectos",
    icon: "reader-outline",
    component: ProjectsPage,
  },
  {
    path: "/users",
    name: "Usuarios",
    icon: "people-outline",
    component: UsersPage,
  }
];

export default routes;
