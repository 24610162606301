import { Fragment, useState, useEffect } from "react";
import styled from "styled-components";

import Checkbox from "@mui/material/Checkbox";

import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import htmlToDraft from "html-to-draftjs";

import toast from "react-hot-toast";

import { BtnSecondary, Input, TextArea, Editor } from "../../components";
import {
  TemplateContainer,
  Form,
  FormGroup,
  Centered,
  AlignRight,
  StyledIcon,
} from "../style";

import { DevButton } from "dev-ui-kit";

import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { create, update } from "../../../domain/services/project.service";
import {
  updateImage,
  deleteImage,
} from "../../../domain/services/images.service";

SwiperCore.use([EffectCoverflow, Pagination]);

const Template = ({ action, project, close }) => {
  const [form, setForm] = useState(
    action !== 2
      ? {
          name: "",
          description: null,
          orderItem: null,
        }
      : project
  );
  const [inputImage, setInputImage] = useState("");
  const [projectImages, setPorjectImages] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (action === 2) {
      project.images.length > 0 && setPorjectImages(project.images);
      project.description &&
        setEditorState(htmlToDraftBlocks(project.description));
    }
  }, [action]);

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    action === 1 ? createProject(form) : updateProject(form);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const createProject = async (_form) => {
    try {
      if (projectImages.length > 0) _form.images = projectImages;

      const res = await create(_form);
      if (res.status && res.status !== 201) {
        toast.error(res.message);
        return;
      }
      toast.success("Proyecto creado correctamente");
      preClose();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateProject = async (_form) => {
    try {
      let updateFields = {};
      let aKeys = Object.keys(project).sort();
      let bKeys = Object.keys(_form).sort();

      for (let i = 0; i < aKeys.length; i++) {
        if (project[aKeys[i]] !== _form[bKeys[i]]) {
          updateFields[bKeys[i]] = _form[bKeys[i]];
        }
      }

      //TODO: validar elementos nuevos o restantes
      if (
        projectImages.length > 0 &&
        JSON.stringify(projectImages) !== JSON.stringify(project.images)
      )
        updateFields.images = projectImages;

      if (Object.entries(updateFields).length === 0) {
        toast.success("Nada para actualizar");
        return;
      }

      updateFields.id = project.id;
      const res = await update(updateFields);
      if (res.status) {
        toast.error(res.message);
        return;
      }
      toast.success("Proyecto actualizado correctamente");
      preClose();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const addImage = () => {
    if (inputImage === "") return;
    setPorjectImages([...projectImages, { image: inputImage, default: false }]);
    setInputImage("");
  };

  const preClose = () => {
    setPorjectImages([]);
    setForm({
      name: { value: "", isValid: null },
      description: "",
      orderItem: 0,
    });
    setInputImage("");
    close();
  };

  const selectDefaultImage = async (_img) => {
    let newProjectImages = [];
    for await (const img of projectImages) {
      if (img.id) {
        if (img.id === _img.id) {
          const res = await updateImage({ ...img, default: !img.default });
          if (!res.status) {
            newProjectImages.push(res);
            continue;
          }
          newProjectImages.push(img);
          toast.error("No se pudo actualizar la imágen");
          continue;
        }
        newProjectImages.push(img);
      } else {
        newProjectImages.push({ ...img, default: !img.default });
      }
    }
    setPorjectImages(() => newProjectImages);
  };

  const handleDeleteImage = async (_img) => {
    let newProjectImages;
    const res = await deleteImage(_img.id);
    if (!res.status) {
      newProjectImages = projectImages.filter((image) => image.id !== _img.id);
      setPorjectImages(() => newProjectImages);
      toast.success("Imagen eliminada correctamente");
      return;
    }
    toast.error("No se pudo eliminar la imágen");
  };

  return (
    <Fragment>
      <h1 className="text-center">Projects</h1>
      <TemplateContainer>
        <Centered>
          <Form>
            <FormGroup>
              <span>Name</span>
              <Input
                type="text"
                id="name"
                name="name"
                required
                placeholder="Nombre"
                value={form.name}
                onChange={handleChange}
              />
            </FormGroup>
            <Centered>
              <FormGroup>
                <span>Image</span>
                <Input
                  type="text"
                  name="image"
                  placeholder="URL de la imágen"
                  value={inputImage}
                  onChange={(e) => setInputImage(e.target.value)}
                />
              </FormGroup>
              <StyledIcon cursor>
                <ion-icon
                  onClick={addImage}
                  name="add-circle-outline"
                  title=""
                ></ion-icon>
                <span style={{ color: "var(--black2)" }}>Add image</span>
              </StyledIcon>
            </Centered>
            <FormGroup>
              <span>Órden</span>
              <Input
                type="text"
                name="orderItem"
                placeholder="Órden de salida"
                value={form.orderItem ? form.orderItem : "0"}
                onChange={handleChange}
              />
            </FormGroup>
          </Form>
        </Centered>
          <Centered pt="10px">
            <Editor
              name="description"
              value={editorState}
              onChange={(editorState) => {
                setForm({
                  ...form,
                  description: stateToHTML(editorState.getCurrentContent()),
                });
                setEditorState(editorState);
              }}
            />
          </Centered>
        <Centered pt="100px">
          {/* <ImageUpload onChange={handleInputImageChange} />
          {oversized && (
            <span>"La imagen supera el tamaño máximo permitido de 15mb."</span>
          )} */}
          {projectImages.length > 0 && (
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              pagination={true}
              className="mySwiper"
            >
              {projectImages.map((img, key) => (
                <SwiperSlide key={key}>
                  <img
                    width={"450px"}
                    height={"240px"}
                    src={img.image}
                    alt="images"
                  />
                  {/* <RadioButtom
                    id={img.id}
                    label="Default"
                    value={img.default}
                    changed={selectDefaultImage}
                    isSelected={img.default}
                  /> */}
                  <Center>
                    <Item>
                      <Checkbox
                        checked={img.default}
                        onChange={() => selectDefaultImage(img)}
                      />
                      Default Image
                    </Item>
                    <Item>
                      <StyledIcon onClick={() => handleDeleteImage(img)}>
                        <ion-icon name="trash-outline"></ion-icon>
                      </StyledIcon>
                      Delete Image
                    </Item>
                  </Center>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Centered>
        <AlignRight>
          <BtnSecondary onClick={() => preClose()}>Cancel</BtnSecondary>
          <DevButton onClick={handleSubmit}>Save</DevButton>
        </AlignRight>
      </TemplateContainer>
    </Fragment>
  );
};

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
`;

const Center = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;
export default Template;
