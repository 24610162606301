import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";

import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import htmlToDraft from "html-to-draftjs";

import toast from "react-hot-toast";
import Multiselect from "multiselect-react-dropdown";

import {
  BtnPrimary,
  BtnSecondary,
  Input,
  ImageUpload,
  Editor,
  Modal,
} from "../../components";
import {
  TemplateContainer,
  Form,
  FormGroup,
  Centered,
  AlignRight,
  StyledIcon,
} from "../style";

import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { create, update } from "../../../domain/services/article.service";
import { brandsGetAll } from "../../../domain/services/brand.service";
import { categoriesGetAll } from "../../../domain/services/category.service";
import { tagsGetAll, tagCreate } from "../../../domain/services/tag.service";
import { deleteImage } from "../../../domain/services/images.service";

SwiperCore.use([EffectCoverflow, Pagination]);

const Template = ({ action, article, close }) => {
  const [form, setForm] = useState(
    action !== 2
      ? {
          code: "",
          name: "",
          model: "",
          description: null,
          slug: "",
          price: 0,
          stock: 0,
          orderItem: null,
        }
      : article
  );

  const [inputImage, setInputImage] = useState("");

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [articleBrands, setArticleBrands] = useState([]);
  const [articleCategories, setArticleCategories] = useState([]);
  const [articleTags, setArticleTags] = useState([]);
  const [articleImages, setArticleImages] = useState([]);

  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  const [oversized, setOversized] = useState(false);
  const [modalTag, setModalTag] = useState(false);

  const [newTagValue, setNewTagValue] = useState("");

  useEffect(() => {
    getAllBrands();
    getAllCategories();
    getAllTags();
    if (action === 2) {
      article.brands.length > 0 && setArticleBrands(article.brands);
      article.categories.length > 0 && setArticleCategories(article.categories);
      article.tags.length > 0 && setArticleTags(article.tags);
      article.images.length > 0 && setArticleImages(article.images);

      article.description &&
        setEditorState(htmlToDraftBlocks(article.description));
    }
  }, [action]);

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const getAllCategories = async () => {
    try {
      const res = await categoriesGetAll();
      setCategories(res);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getAllBrands = async () => {
    try {
      const res = await brandsGetAll();
      setBrands(res);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getAllTags = async () => {
    try {
      const res = await tagsGetAll();
      setTags(() => res);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    action === 1 ? createArticle(form) : updateArticle(form);
  };

  const createArticle = async (_form) => {
    try {
      if (articleBrands.length > 0) _form.brands = articleBrands;
      if (articleCategories.length > 0) _form.categories = articleCategories;
      if (articleTags.length > 0) _form.tags = articleTags;
      if (articleImages.length > 0) _form.images = articleImages;

      const res = await create(_form);

      if (res.status && res.status !== 201) {
        toast.error(res.message);
        return;
      }
      toast.success("Article created successfully");
      preClose();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateArticle = async (_form) => {
    try {
      let updateFields = {};
      let aKeys = Object.keys(article).sort();
      let bKeys = Object.keys(_form).sort();

      for (let i = 0; i < aKeys.length; i++) {
        if (article[aKeys[i]] !== _form[bKeys[i]]) {
          updateFields[bKeys[i]] = _form[bKeys[i]];
        }
      }

      //TODO: validar elementos nuevos o restantes
      if (
        articleBrands.length > 0 &&
        JSON.stringify(articleBrands) !== JSON.stringify(article.brands)
      )
        updateFields.brands = articleBrands;
      if (
        articleCategories.length > 0 &&
        JSON.stringify(articleCategories) !== JSON.stringify(article.categories)
      )
        updateFields.categories = articleCategories;
      if (
        articleTags.length > 0 &&
        JSON.stringify(articleTags) !== JSON.stringify(article.tags)
      )
        updateFields.tags = articleTags;
      if (
        articleImages.length > 0 &&
        JSON.stringify(articleImages) !== JSON.stringify(article.images)
      )
        updateFields.images = articleImages;

      if (Object.entries(updateFields).length === 0) {
        toast.success("Nothing to update");
        return;
      }

      updateFields.id = article.id;
      const res = await update(updateFields);
      if (res.status) {
        toast.error(res.message);
        return;
      }
      toast.success("Article updated successfully");
      preClose();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const createNewTag = async () => {
    try {
      const res = await tagCreate(newTagValue);
      if (res.status && res.status !== 201) {
        toast.error(res.message);
        return;
      }
      toast.success("Tag created successfully");
      getAllTags();
      setNewTagValue("");
      setModalTag(!modalTag);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const isPriceOrStock = name === "price" || name === "stock";
    setForm({ ...form, [name]: isPriceOrStock ? parseInt(value) : value });
  };

  // const handleInputImageChange = (event) => {
  //   let files = event.target.files;
  //   let nameImage = files[0].name.split(".")[0];
  //   let sizeByte = files[0].size;
  //   let sizekiloBytes = parseInt(sizeByte / 15360);

  //   if (sizekiloBytes > 15360) {
  //     setOversized(true);
  //     return;
  //   }
  //   oversized && setOversized(false);

  //   let reader = new FileReader();
  //   reader.readAsDataURL(files[0]);
  //   reader.onload = (e) => addImage(e.target.result, nameImage);
  // };

  const addImage = () => {
    if (inputImage === "") return;
    setArticleImages([...articleImages, { image: inputImage, default: true }]);
    setInputImage("");
  };

  const handleDeleteImage = async (_img) => {
    let newArticleImages;
    const res = await deleteImage(_img.id);
    if (!res.status) {
      newArticleImages = articleImages.filter((image) => image.id !== _img.id);
      setArticleImages(() => newArticleImages);
      toast.success("Imagen eliminada correctamente");
      return;
    }
    toast.error("No se pudo eliminar la imágen");
  };

  const preClose = () => {
    setArticleBrands([]);
    setArticleCategories([]);
    setArticleTags([]);
    setArticleImages([]);
    setForm({
      code: "",
      name: "",
      model: "",
      description: null,
      slug: "",
      price: 0,
      stock: 0,
    });
    setInputImage("");
    close();
  };

  return (
    <Fragment>
      <h1 className="text-center">Articles</h1>
      <TemplateContainer>
        <Centered>
          <Modal
            title="Create a new tag"
            open={modalTag}
            valueInput={newTagValue}
            handleChangeInput={(e) => setNewTagValue(e.target.value)}
            handleSubmitInput={createNewTag}
            handleClose={() => setModalTag(!modalTag)}
          />
          {articleImages.length > 0 && (
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              pagination={true}
              className="mySwiper"
            >
              {articleImages.map((img, key) => (
                <SwiperSlide key={key}>
                  <img
                    width={"450px"}
                    height={"240px"}
                    src={img.image}
                    alt="images"
                  />
                  <Center>
                    <Item>
                      <StyledIcon onClick={() => handleDeleteImage(img)}>
                        <ion-icon name="trash-outline"></ion-icon>
                      </StyledIcon>
                      Delete Image
                    </Item>
                  </Center>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          <Form>
            <FormGroup>
              <span>Nombre</span>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                required
                value={form.name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <span>Modelo</span>
              <Input
                type="text"
                name="model"
                placeholder="Modelo"
                value={form.model}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <span>Slug</span>
              <Input
                type="text"
                name="slug"
                placeholder="Slug"
                value={form.slug}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <span>Código</span>
              <Input
                type="text"
                name="code"
                placeholder="Código"
                value={form.code}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <span>Precio</span>
              <Input
                type="number"
                name="price"
                placeholder="Precio"
                value={form.price}
                onChange={handleChange}
              />
            </FormGroup>
            <Centered>
              <FormGroup>
                <span>Stock</span>
                <Input
                  width="90%"
                  type="number"
                  name="stock"
                  placeholder="Stock"
                  value={form.stock}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <span>Órden</span>
                <Input
                  width="90%"
                  type="number"
                  name="orderItem"
                  placeholder="Órden de salida"
                  value={form.orderItem ? form.orderItem : "0"}
                  onChange={handleChange}
                />
              </FormGroup>
            </Centered>
            <Centered>
              <FormGroup>
                <span>Image</span>
                <Input
                  type="text"
                  name="image"
                  placeholder="URL Image"
                  value={inputImage}
                  onChange={(e) => setInputImage(e.target.value)}
                />
              </FormGroup>
              <StyledIcon cursor>
                <ion-icon
                  onClick={addImage}
                  name="add-circle-outline"
                  title=" "
                ></ion-icon>
              </StyledIcon>
            </Centered>
            {brands.length > 0 && (
              <div>
                <span className="span-custom">Brands</span>
                <Multiselect
                  placeholder={articleBrands.length <= 0 ? "Brands" : ""}
                  options={brands} // Options to display in the dropdown
                  selectedValues={articleBrands} // Preselected value to persist in dropdown
                  onSelect={(_brands) => setArticleBrands(_brands)} // Function will trigger on select event
                  onRemove={(_brands) => setArticleBrands(_brands)} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </div>
            )}
            {categories.length > 0 && (
              <div>
                <span className="span-custom">Categories</span>
                <Multiselect
                  placeholder={
                    articleCategories.length <= 0 ? "Categories" : ""
                  }
                  options={categories} // Options to display in the dropdown
                  selectedValues={articleCategories} // Preselected value to persist in dropdown
                  onSelect={(_categories) => setArticleCategories(_categories)} // Function will trigger on select event
                  onRemove={(_categories) => setArticleCategories(_categories)} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </div>
            )}
            {tags.length > 0 && (
              <div>
                <span className="span-custom">Tags</span>
                <Centered>
                  <Multiselect
                    placeholder={articleTags.length <= 0 ? "Tags" : ""}
                    options={tags} // Options to display in the dropdown
                    selectedValues={articleTags} // Preselected value to persist in dropdown
                    onSelect={(_tags) => setArticleTags(_tags)} // Function will trigger on select event
                    onRemove={(_tags) => setArticleTags(_tags)} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                  />
                  <StyledIcon cursor>
                    <ion-icon
                      onClick={() => setModalTag(!modalTag)}
                      name="add-circle-outline"
                      title=" "
                    ></ion-icon>
                  </StyledIcon>
                </Centered>
              </div>
            )}
          </Form>
        </Centered>
        <Centered pt="50px">
          {/* <ImageUpload onChange={handleInputImageChange} />
          {oversized && (
            <span>"La imagen supera el tamaño máximo permitido de 15mb."</span>
          )} */}
        </Centered>
        <Centered pt="10px">
          <Editor
            name="description"
            value={editorState}
            onChange={(editorState) => {
              setForm({
                ...form,
                description: stateToHTML(editorState.getCurrentContent()),
              });
              setEditorState(editorState);
            }}
          />
        </Centered>
        <AlignRight>
          <BtnSecondary onClick={() => preClose()}>Cancel</BtnSecondary>
          <BtnPrimary onClick={handleSubmit}>Save</BtnPrimary>
        </AlignRight>
      </TemplateContainer>
    </Fragment>
  );
};

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
`;

const Center = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
`;

export default Template;
