import React, { Fragment, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { Table, Spinner } from "../../components";
import { DevButton } from "dev-ui-kit";

import Template from "./Template";
import { AlignRight, Centered } from "../style";
import { getAll, _delete } from "../../../domain/services/project.service";

function ProjectsPage() {
  const [articles, setProyects] = useState([]);
  const [project, setProyect] = useState(null);
  const [action, setAction] = useState(0); //1 - new, 2 = edit, 3 = delete
  const [loading, setLoading] = useState(false);

  const cells = ["id", "name", "orderItem"];

  useEffect(() => {
    setLoading((loading) => !loading);
    getAllProyects();
  }, [action]);

  const getAllProyects = async () => {
    try {
      let response = await getAll();
      setProyects(response.sort((a, b) => a.orderItem - b.orderItem));
      setLoading((loading) => !loading);
    } catch (error) {
      toast.error(error.message);
      setLoading((loading) => !loading);
    }
  };

  const deleteProyect = (item) => {
    try {
      _delete(item.id);
      toast.success("Proyect deleted successfully");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEdit = (item) => {
    setProyect(item);
    setAction(2);
  };

  const handleDelete = (item) => {
    deleteProyect(item);
    setAction(3);
  };

  return (
    <Fragment>
      {(action === 0 || action === 3) && (
        <Fragment>
          <h1 className="text-center">Projects</h1>
          <AlignRight>
            <DevButton onClick={() => setAction(1)}>Nuevo</DevButton>
          </AlignRight>
          <Table
            title="All Proyects"
            cells={cells}
            items={articles}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
          {loading && (
            <Centered>
              <Spinner />
            </Centered>
          )}
        </Fragment>
      )}
      {action !== 0 && action !== 3 && (
        <Fragment>
          <Template
            action={action}
            project={project}
            close={() => setAction(0)}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

export default ProjectsPage;
