// /src/hooks/useAuth.tsx
import React, { useState, createContext, useContext, useEffect } from "react";
import { decodeToken } from "../utils";

import { useHistory } from "react-router-dom";

// Create the context
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  // Using the useState hook to keep track of the value authed (if a
  // user is logged in)
  const history = useHistory();
  const [authed, setAuthed] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("token");

    if (token && decodeToken(token).exp < Date.now() / 1000) {
      localStorage.removeItem("token");
      return;
    } else if (!token) {
      setAuthed(false);
      return;  
    }
    setAuthed(true);
  }, []);

  useEffect(() => {
    if (authed) history.push("/");
  }, [authed, history]);

  return (
    // Using the provider so that ANY component in our application can
    // use the values that we are sending.
    <AuthContext.Provider value={{ authed, setAuthed }}>
      {children}
    </AuthContext.Provider>
  );
};

// Finally creating the custom hook
export const useAuth = () => useContext(AuthContext);
