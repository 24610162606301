import { http } from "../http";

export const imageRepository = {
  updateImage: async (image: any) => {
    const { id } = image;
    return await http.patch(`/image/${id}`, image);
  },

  deleteImage: async (id: Number) => {
    return await http.delete(`/image/${id}`);
  },
};
