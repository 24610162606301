import React from "react";
import { useHistory } from "react-router-dom";
import { TopBar, Toogle, Search, UserImage, StyledIcon } from "./style";
import { useAuth } from "../../../hooks/useAuth";

function TopComponent({ toogle, setToogle }) {
  const history = useHistory();
  const { setAuthed } = useAuth(); 
  const handleLogout = () => {
    localStorage.removeItem("token");
    setAuthed(false);
    history.push("/auth")
  };
  return (
    <TopBar>
      <Toogle onClick={() => setToogle(!toogle)}>
        <ion-icon name="menu-outline"></ion-icon>
      </Toogle>
      <Search>
        <label>
          <input type="text" placeholder="Search Here" />
          <ion-icon name="search-outline"></ion-icon>
        </label>
      </Search>
      <UserImage>
        {/* <img src={require("../../../assets/img/user.jpg")} alt="User" /> */}
        <StyledIcon onClick={() => handleLogout()}>
          <ion-icon name="enter-outline"></ion-icon>
        </StyledIcon>
      </UserImage>
    </TopBar>
  );
}

export default TopComponent;
