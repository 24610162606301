import { Fragment, useState, useEffect } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";

import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { BtnPrimary, BtnSecondary, Input } from "../../components";
import {
  TemplateContainer,
  Form,
  FormGroup,
  Centered,
  AlignRight,
  StyledIcon,
} from "../style";

import Checkbox from "@mui/material/Checkbox";

import { create, update } from "../../../domain/services/brand.service";

import {
  updateImage,
  deleteImage,
} from "../../../domain/services/images.service";

SwiperCore.use([EffectCoverflow, Pagination]);

const Template = ({ action, brand, close }) => {
  const [form, setForm] = useState({
    name: "",
    aditionalData: "",
  });
  const [inputImage, setInputImage] = useState("");
  const [brandImages, setBrandImages] = useState([]);

  useEffect(() => {
    if (action === 2) {
      setForm(brand);
      brand.images && brand.images.length > 0 && setBrandImages(brand.images);
    }
  }, [action]);

  const handleSubmit = (event) => {
    event.preventDefault();
    action === 1 ? createBrand(form) : updateBrand(form);
  };

  const createBrand = (_form) => {
    try {
      if (brandImages.length > 0) _form.images = brandImages;
      create(_form);
      toast.success("Brand created successfully");
      close();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateBrand = async (_form) => {
    try {
      let updateFields = {};
      let aKeys = Object.keys(brand).sort();
      let bKeys = Object.keys(_form).sort();

      for (let i = 0; i < aKeys.length; i++) {
        if (brand[aKeys[i]] !== _form[bKeys[i]]) {
          updateFields[bKeys[i]] = _form[bKeys[i]];
        }
      }

      if (
        brandImages.length > 0 &&
        JSON.stringify(brandImages) !== JSON.stringify(brand.images)
      )
        updateFields.images = brandImages;

      if (Object.entries(updateFields).length === 0) {
        toast.success("Nada para actualizar");
        return;
      }

      updateFields["id"] = brand.id;
      const res = await update(updateFields);
      if (res.status) {
        toast.error(res.message);
        return;
      }
      toast.success("Brand updated successfully");
      close();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const addImage = () => {
    if (inputImage === "") return;
    setBrandImages([...brandImages, { image: inputImage, default: false }]);
    setInputImage("");
  };

  const handleDeleteImage = async (_img) => {
    let newProjectImages;
    const res = await deleteImage(_img.id);
    if (!res.status) {
      newProjectImages = brandImages.filter((image) => image.id !== _img.id);
      setBrandImages(() => newProjectImages);
      toast.success("Imagen eliminada correctamente");
      return;
    }
    toast.error("No se pudo eliminar la imágen");
  };

  const selectDefaultImage = async (_img) => {
    let newProjectImages = [];
    for await (const img of brandImages) {
      if (img.id) {
        if (img.id === _img.id) {
          const res = await updateImage({ ...img, default: !img.default });
          if (!res.status) {
            newProjectImages.push(res);
            continue;
          }
          newProjectImages.push(img);
          toast.error("No se pudo actualizar la imágen");
          continue;
        }
        newProjectImages.push(img);
      } else {
        newProjectImages.push({ ...img, default: !img.default });
      }
    }
    setBrandImages(() => newProjectImages);
  };

  return (
    <Fragment>
      <h1 className="text-center">Marcas</h1>
      <TemplateContainer>
        <Centered>
          <Form>
            <FormGroup>
              <span>Nombre</span>
              <Input
                type="text"
                name="name"
                placeholder="Nombre"
                required
                value={form.name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <span>Datos adicionales</span>
              <Input
                type="text"
                name="aditionalData"
                placeholder="Datos adicionales"
                value={form.aditionalData}
                onChange={handleChange}
              />
            </FormGroup>
            <Centered>
              <FormGroup>
                <span>Imágen</span>
                <Input
                  type="text"
                  name="image"
                  placeholder="URL de la imágen"
                  value={inputImage}
                  onChange={(e) => setInputImage(e.target.value)}
                />
              </FormGroup>
              <StyledIcon cursor>
                <ion-icon
                  onClick={addImage}
                  name="add-circle-outline"
                  title=""
                ></ion-icon>
                <span style={{ color: "var(--black2)" }}>Agregar</span>
              </StyledIcon>
            </Centered>
          </Form>
        </Centered>
        <Centered pt="100px">
          {/* <ImageUpload onChange={handleInputImageChange} />
          {oversized && (
            <span>"La imagen supera el tamaño máximo permitido de 15mb."</span>
          )} */}
          {brandImages.length > 0 && (
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              pagination={true}
              className="mySwiper"
            >
              {brandImages.map((img, key) => (
                <SwiperSlide key={key}>
                  <img
                    width={"450px"}
                    height={"240px"}
                    src={img.image}
                    alt="images"
                  />
                  {/* <RadioButtom
                    id={img.id}
                    label="Default"
                    value={img.default}
                    changed={selectDefaultImage}
                    isSelected={img.default}
                  /> */}
                  <Center>
                    <Item>
                      <Checkbox
                        checked={img.default}
                        onChange={() => selectDefaultImage(img)}
                      />
                      Default Image
                    </Item>
                    <Item>
                      <StyledIcon onClick={() => handleDeleteImage(img)}>
                        <ion-icon name="trash-outline"></ion-icon>
                      </StyledIcon>
                      Delete Image
                    </Item>
                  </Center>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Centered>
        <AlignRight>
          <BtnSecondary onClick={() => close()}>Cancel</BtnSecondary>
          <BtnPrimary onClick={handleSubmit}>Save</BtnPrimary>
        </AlignRight>
      </TemplateContainer>
    </Fragment>
  );
};

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
`;

const Center = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export default Template;
