import React, { useState } from "react";
import { Container, StyledIcon } from "./style";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";

export default function BasicTable({
  title,
  cells,
  items,
  handleEdit,
  handleDelete,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? (
            <ion-icon name="arrow-redo-outline"></ion-icon>
          ) : (
            <ion-icon name="arrow-undo-outline"></ion-icon>
          )}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <ion-icon name="arrow-forward-outline"></ion-icon>
          ) : (
            <ion-icon name="arrow-back-outline"></ion-icon>
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <ion-icon name="arrow-back-outline"></ion-icon>
          ) : (
            <ion-icon name="arrow-forward-outline"></ion-icon>
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? (
            <ion-icon name="arrow-redo-outline"></ion-icon>
          ) : (
            <ion-icon name="arrow-redo-outline"></ion-icon>
          )}
        </IconButton>
      </Box>
    );
  }

  return (
    <Container>
      <h4>{title}</h4>
      <TableContainer
        component={Paper}
        sx={{
          height: 400,
        }}
      >
        <Table
          sx={{ minWidth: 650, height: "max-content" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {cells &&
                cells.map((cell, key) => {
                  let render = <></>;
                  if (cells[0] === cell) {
                    render = (
                      <TableCell key={key} align="left">
                        {cell.toUpperCase()}
                      </TableCell>
                    );
                  } else {
                    render = (
                      <TableCell key={key} align="left">
                        {cell.toUpperCase()}
                      </TableCell>
                    );
                  }
                  return render;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? items.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : items
            ).map((item) => (
              <TableRow
                key={item.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {Object.entries(item).map(([key]) => {
                  if (cells.includes(key)) {
                    let render = <></>;
                    render = (
                      <TableCell key={key} align="left">
                        {typeof item[key] === "boolean"
                          ? item[key]
                            ? "True"
                            : "False"
                          : item[key]}
                      </TableCell>
                    );
                    return render;
                  } else return null;
                })}
                <TableCell align="left">
                  <StyledIcon onClick={() => handleEdit(item)}>
                    <ion-icon name="create-outline"></ion-icon>
                  </StyledIcon>
                </TableCell>
                <TableCell align="left">
                  <StyledIcon onClick={() => handleDelete(item)}>
                    <ion-icon name="trash-outline"></ion-icon>
                  </StyledIcon>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
}
