const domain = window.location.hostname.includes("localhost")
  ? "http://localhost:5000/v1/api"
  : "https://bocata.tedesco.es/v1/api";

const get = async (path) => {
  const response = await fetch(domain + path, {
    method: "GET",
    headers: {
      "Content-Type":"application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: window.localStorage.getItem("token"),
    },
  });
  return await response.json();
};

const post = async (path, body) => {
  const response = await fetch(domain + path, {
    method: "POST",
    headers: {
      "Content-Type":"application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: window.localStorage.getItem("token"),
    },
    body: JSON.stringify(body),
  });
  return await response.json();
};

const patch = async (path, body) => {
  const response = await fetch(domain + path, {
    method: "PATCH",
    headers: {
      "Content-Type":"application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: window.localStorage.getItem("token"),
    },
    body: JSON.stringify(body),
  });
  return await response.json();
};

const _delete = async (path) => {
  const response = await fetch(domain + path, {
    method: "DELETE",
    headers: {
      "Content-Type":"application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: window.localStorage.getItem("token"),
    },
  });
  return await response.json();
};

export const http = {
  get,
  post,
  patch,
  delete: _delete,
};
